@font-face {
    font-family: 'Inter';
    src: url('./Inter-Regular.ttf'),
}

@font-face {
    font-family: 'Inter Black';
    src: url('./Inter-Black.ttf'),
}

@font-face {
    font-family: 'Inter Bold';
    src: url('./Inter-Bold.ttf'),
}

@font-face {
    font-family: 'Inter Extra Bold';
    src: url('./Inter-ExtraBold.ttf'),
}

@font-face {
    font-family: 'Inter Extra Light';
    src: url('./Inter-ExtraLight.ttf'),
}

@font-face {
    font-family: 'Inter Light';
    src: url('./Inter-Light.ttf'),
}

@font-face {
    font-family: 'Inter Medium';
    src: url('./Inter-Medium.ttf'),
}

@font-face {
    font-family: 'Inter Semi Bold';
    src: url('./Inter-SemiBold.ttf'),
}

@font-face {
    font-family: 'Inter Thin';
    src: url('./Inter-Thin.ttf'),
}
