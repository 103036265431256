@import url('../fonts/fonts.css');
@import url('./colors.css');
@import url('./mobile.css');

@media only screen and (min-width: 750px) {
    .container {
        margin: 130px 150px 10px 150px;
        padding: 0 15px;
        min-height: 77vh;
        display: flex;
        flex-direction: column;
    }
}

:root {
    scrollbar-color: var(--wave-color) var(--bg-color);
    scrollbar-width: thin;
}

body {
    background-color: var(--bg-color);
    color: white;
    font-family: 'Inter';
    font-size: 1.5rem;
    letter-spacing: 2px;
}

.wave {
    background-image: url('../assets/wave.svg');
    width: 100%;
    height: 125px;
    position: absolute;
    top: 0;
    overflow: hidden;
    z-index: -1;
}

p {
    font-size: 1.1rem;
    line-height: 1.75rem;
    letter-spacing: 1.5px;
}

p a, a:visited {
    color: var(--header-color);
    font-size: 1.1rem;
    font-family: 'Inter Bold';
    letter-spacing: 0px;
    text-decoration: none;
    letter-spacing: 1px;
}

p a:hover {
    color: var(--text-link-hover-color);
}

h1, h2, h3 {
    color: var(--header-color);
    font-family: 'Inter Bold';
    letter-spacing: 0px;
    text-transform: lowercase;
}

h1 {
    margin: 20px 0;
    font-size: 3.5rem;
    text-align: center;
}

h2 {
    margin: 0;
    font-size: 2.25rem;
}

h3 {
    margin: 7px 0;
    font-size: 6rem;
}

h4 {
    font-weight: normal;
    letter-spacing: 0;
    font-size: 1rem;
    margin: 5px 0;
}

.title-only {
    align-self: flex-end;
    justify-content: flex-end;
    display: flex;
    padding: 0 15px;
}

.folders {
    display: flex;
    width: 90%;
    height: auto;
    min-height: 65vh;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}

.folder-item {
    flex-basis: 30%;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.intro-p  {
    margin: 0 50px;
    text-align: center;
    padding-bottom: 50px;
}

.button-tag, .button-tag:hover {
    background: none;
    text-decoration: none;
    font-family: 'Inter';
    margin: 0;
    padding: 0;
}