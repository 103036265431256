@import url('../fonts/fonts.css');

.me {
    padding-bottom: 50px;
}

.me img {
    width: 300px;
}

.me-left {
    float: left;
    padding-right: 30px;
}

.me-right {
    float: right;
    padding-left: 30px;
}

.smol {
    max-width: 200px;
}

@media only screen and (max-width: 750px) {
    .me-left, .me-right {
        float: none;
        padding: 15px;
    }
    .me, h2 {
        text-align: center;
    }
    h2 {
        font-size: 2rem;
    }
}