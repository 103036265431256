@import url('../fonts/fonts.css');
@import url('./colors.css');

.wl-item-container {
    display: flex;
    padding: 0 0 20px 0;
}

.wl-item-header {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
}

.wl-item-header h2 {
    padding: 0 35px 0 0;
    overflow-wrap: break-word;
    overflow: auto;
}

.wl-item-content {
    width: 100%;
}

.wl-tags {
    flex-wrap: wrap;
    display: flex;
    padding: 10px 0;
    align-items: center;
}

img {
    float: left;
    max-width: 300px;
    margin: auto;
    padding: 15px;
}

button {
    border: none;
    background-color: #3A4856;
    border-radius: 20px;
    cursor: pointer;
    padding: 5px 15px;
    margin: 10px;
    color: #FFFFFF;
    font-size: 1rem;
    font-family: 'Inter Bold';
    letter-spacing: 0px;
    letter-spacing: 1px;
}

button:hover, .active {
    background-color: #537491;
}

.indicator-divider {
    color: var(--header-color);
    font-size: 1.1rem;
    font-family: 'Inter Bold';
    letter-spacing: 0px;
    text-decoration: none;
    padding: 0 10px;
}

.active {
    font-family: 'Inter Extra Bold';
}

.wl-img-indicators {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    font-family: 'Inter';
    letter-spacing: 2px;
    text-decoration: none;
    flex-wrap: wrap;
}

.filter-sort-section {
    display: flex;
    width: 100%;
    padding: 5px 0;
    justify-content: flex-end;
    align-items: flex-start;
}

.filter-section {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.tag-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 15px;
}


@media only screen and (max-width: 750px) {
    img {
        float: none;
    }
    .wl-img-indicators, .wl-tags, .wl-item-header {
        justify-content: center;
    }
    .wl-item-container, .wl-item-header {
        text-align: center;
    }
    .wl-item-header h2 {
        padding: 0;
    }
    .filter-sort-section {
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }
    .filter-section {
        align-items: center;
    }
    .tag-list {
        justify-content: center;
    }
}