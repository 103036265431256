@import url('../fonts/fonts.css');
@import url('./colors.css');

@media only screen and (max-width: 750px) {
    .nav-title, a, a:visited, .menu-item {
        color: var(--header-color);
        font-family: 'Inter Bold';
        letter-spacing: 0px;
        text-transform: lowercase;
        text-decoration: none;
    }

    .container {
        margin: 8vh 10px 10px 10px;
        padding: 0 10px;
        min-height: 77vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    p {
        text-align: center;
    }


}