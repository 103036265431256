@import url('../fonts/fonts.css');

.brand-note {
    padding: 5px;
    display: flex;
    align-items: flex-start;
}

.brand-note img {
    margin-right: 20px;
}

.brand-note p {
    text-align: left;
    padding-bottom: 25px;
}

@media only screen and (max-width: 750px) {
    .brand-note {
        padding: 5px;
        text-align: center;
        display: block;
    }
    .brand-note p {
        text-align: center;
    }
}