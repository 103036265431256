@import url('../fonts/fonts.css');

.folder-container, .folder-svg {
    width: fit-content;
}

.folder-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.folder-title, .folder-title a {
    color: #1D2839;
    font-family: 'Inter Bold';
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: lowercase;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 10px;
    text-align: center;
}

.folder-title a:hover {
    color: #3B4B74;
}
